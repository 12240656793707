<template>
  <div class="user-list-page">
    <h2>Users</h2>
    <div class="d-flex justify-content-between align-items-center py-3">
      <form method="get">
        <div class="input-group input-group-search">
          <input type="text" name="keyword" class="form-control" placeholder="Search user...">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="submit">
              <b-icon-search />
            </button>
          </div>
        </div>
      </form>

      <router-link :to="{ name: 'user-create' }" class="btn btn-primary">
        <i class="bi-plus"></i>
        Create new user
      </router-link>
    </div>

    <smart-table
      :header="header"
      :items="items"
    />
  </div>
</template>

<script>
import SmartTable from '../components/SmartTable.vue'

export default {
  name: 'UserList',

  components: {
    SmartTable
  },

  computed: {
    items () {
      const items = this.data ? this.data.data : []
      return items.map(item => {
        return {
          ...item,
          name: {
            text: item.name,
            to: {
              name: 'user-edit',
              params: { id: item.id }
            }
          },
          email: {
            text: item.email,
            to: {
              name: 'user-edit',
              params: { id: item.id }
            }
          },
          created_at: new Date(item.created_at)
        }
      })
    }
  },

  data () {
    return {
      header: [
        { data: 'Name', field: 'name', type: 'link' },
        { data: 'Email', field: 'email', type: 'link' },
        { data: 'Active?', field: 'status' },
        { data: 'Created', field: 'created_at', type: 'datetime' }
      ],

      data: null
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    async fetch () {
      this.loading = true
      try {
        const resp = await this.$users.list()
        this.data = resp.data
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
